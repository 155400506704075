import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Typography from '@mui/material/Typography';

const SectionHeader = ({ title, subtitle, ...rest }) => {
  return (
    <Row className="justify-content-center text-center" {...rest}>
      <Col lg={8} xl={7} xxl={6} className="col-xxl-6">
        <Typography
          variant="h3"
          component="h3"
          mb={4}
          fontFamily={'Poppins'}
          fontWeight={700}
          align={'center'}
        >
          {title}
        </Typography>
        <p className="lead">{subtitle}</p>
      </Col>
    </Row>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default SectionHeader;
