import React from 'react';
import Section from 'components/common/Section';
import { Table } from 'react-bootstrap';
import { useRocketContext } from 'context/RocketContext';
import { Box, Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const RocketsScoreboard = () => {
  // useEffect to retrieve scoreboard data from Airtable
  // const [loading, setLoading] = useState(true);
  // const [juniorScores, setJuniorScores] = useState([]);
  // const [seniorScores, setSeniorScores] = useState([]);
  const {
    junior_division_scores,
    senior_division_scores,
    certificate_url,
    loading
  } = useRocketContext();

  // useEffect(() => {
  //   const retrieveScoreboard = async () => {
  //     try {
  //       setLoading(true);
  //       const jr_score_data = await getRocketsJuniorScores();
  //       const sr_score_data = await getRocketsSeniorScores();
  //       console.log('jr_score_data', jr_score_data.data);
  //       console.log('sr_score_data', sr_score_data.data);
  //       setJuniorScores(jr_score_data.data.records_data);
  //       setSeniorScores(sr_score_data.data.records_data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   retrieveScoreboard();
  // }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        py: 5,
        boxSizing: 'border-box'
      }}
    >
      <Section
        style={{
          padding: 0,
          width: '100%'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
            // border: '1px solid red'
          }}
        >
          <Typography variant="h3" mb={5} sx={{ fontWeight: 700 }}>
            2024 Rocket Team Challenge Scoreboard
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              window.open(certificate_url, '_blank');
            }}
            startIcon={<DownloadIcon />}
          >
            Download Participation Certificate
          </Button>
          {loading && (
            <h3 className="text-center mt-4">Loading scoreboard...</h3>
          )}
          {!loading && (
            <Box mt={3}>
              <h4>Senior Division</h4>
              <Table responsive>
                <thead>
                  <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Team</th>
                    <th scope="col">Team Members</th>
                    {/* <th scope="col">Team School</th> */}
                    <th scope="col">Recorded Altitude (ft)</th>
                    <th scope="col" style={{ width: '200px' }}>
                      Special Awards
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* map senior score into table */}
                  {senior_division_scores.map((team, index) => (
                    <tr
                      style={{
                        fontWeight:
                          index < 4 && team.fx_calc_score > 0
                            ? 'bold'
                            : 'normal'
                      }}
                      key={index}
                    >
                      <td>{team.fx_calc_score > 0 ? index + 1 : 'DNQ'}</td>
                      <td>{team.lookup_team_name[0]}</td>
                      <td>{team.lookup_students[0]}</td>
                      {/* <td>{team.rollup_team_school}</td> */}
                      <td>
                        {team.input_measured_apogee > 0
                          ? team.input_measured_apogee
                          : 'No altimeter data'}
                      </td>
                      <td>{team.lookup_team_special_awards}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <h4 style={{ marginTop: '16px' }}>Junior Division</h4>
              <Table responsive>
                <thead>
                  <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Team</th>
                    <th scope="col">Team Members</th>
                    {/* <th scope="col">Team School</th> */}
                    <th scope="col">Recorded Altitude (ft)</th>
                    <th scope="col" style={{ width: '200px' }}>
                      Special Awards
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* map senior score into table */}
                  {junior_division_scores.map((team, index) => (
                    <tr
                      style={{
                        fontWeight:
                          index < 4 && team.fx_calc_score > 0
                            ? 'bold'
                            : 'normal'
                      }}
                      key={index}
                    >
                      <td>{team.fx_calc_score > 0 ? index + 1 : 'DNQ'}</td>
                      <td>{team.lookup_team_name[0]}</td>
                      <td>{team.lookup_students[0]}</td>
                      {/* <td>{team.rollup_team_school}</td> */}
                      <td>
                        {team.input_measured_apogee > 0
                          ? team.input_measured_apogee
                          : 'No altimeter data'}
                      </td>
                      <td>
                        {team.lookup_team_special_awards?.map((award, idx) => (
                          <p key={idx}>{award}</p>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          )}
        </Box>
      </Section>
    </Box>
  );
};

export default RocketsScoreboard;
