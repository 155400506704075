import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { event_table, scoring_table } from 'configs/airtable';

// Create a context
const RocketContext = createContext();

// Custom hook to use RocketContext
export const useRocketContext = () => useContext(RocketContext);

export const RocketProvider = ({ children }) => {
  const [junior_division_scores, setJuniorDivisionScores] = useState([]);
  const [senior_division_scores, setSeniorDivisionScores] = useState([]);
  const [certificate_url, setCertificateUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    console.log('Fetching data from Airtable');
    try {
      const junior_response = await scoring_table
        .select({
          sort: [
            {
              field: 'fx_calc_score',
              direction: 'desc'
            },
            {
              field: 'input_measured_apogee',
              direction: 'desc'
            }
          ]
        })
        .all();
      const senior_response = await scoring_table
        .select({
          sort: [
            {
              field: 'fx_calc_score',
              direction: 'desc'
            }
          ]
        })
        .all();

      const junior_data = junior_response.map(record => record.fields);
      setJuniorDivisionScores(
        junior_data.filter(
          team => team['lookup_team_division'][0] === 'Junior Division'
        )
      );
      const senior_data = senior_response.map(record => record.fields);
      setSeniorDivisionScores(
        senior_data.filter(
          team => team['lookup_team_division'][0] === 'Senior Division'
        )
      );

      const certificate_url_response = await event_table.find(
        'recY2NIaUYkGJ0yRh'
      );
      setCertificateUrl(certificate_url_response.fields.certificate[0]?.url);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading data...</div>;
  }

  return (
    <RocketContext.Provider
      value={{
        junior_division_scores,
        senior_division_scores,
        certificate_url,
        loading,
        error
      }}
    >
      {children}
    </RocketContext.Provider>
  );
};

RocketProvider.propTypes = {
  children: PropTypes.node.isRequired
};
