import React from 'react';
// import Hero from './Hero';
// import HeroBridges from './HeroBridges';
// import NavbarStandard from './NavbarStandard';
// import UpcomingEvents from './UpcomingEvents';
// import Scoreboard from 'components/scoreboard/Scoreboard';
// import Cta from './Cta';

// Scoreboards
// import CNYSEFScoreboard from 'components/scoreboard/CNYSEFScoreboard';
// import BridgeScoreboard from 'components/scoreboard/BridgeScoreboard';
// import RoboticsScoreboard from 'components/scoreboard/RoboticsScoreboard';
// import Scoreboard from 'components/scoreboard/Scoreboard';
import RocketsScoreboard from 'components/scoreboard/RocketsScoreboard';
// import NYSSCScoreboard from 'components/scoreboard/NYSSCScoreboard';
// import SteamboatScoreboard from 'components/scoreboard/SteamboatScoreboard';
// import API from 'configs/api';
// import BridgeScoreboard from 'components/scoreboard/BridgeScoreboard';
// import RocketRegistration from './RocketRegistration';

//TODO: Reimport Footer
// import FooterStandard from './FooterStandard';s

const Landing = () => {
  // const [displayPreviousEventScoreboard, setDisplayPreviousEventScoreboard] =
  //   useState(false);
  // const get_current_event = async () => {
  //   try {
  //     const { data } = await API.get('/events/rece0DQHsBpykLqBs');
  //     // look at data.publish_scores, if true, setdisplayCurrentEventScoreboard to true, else false. If it doesn't exist/undefined, set to false
  //     console.log('Current event isssa:', data);
  //     console.log('Publish scores is:', data.publish_scores);
  //     // setDisplayPreviousEventScoreboard(data.publish_scores);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   get_current_event();
  // }, []);
  return (
    <>
      {/* <NavbarStandard /> */}
      {/* <RocketRegistration /> */}
      {/* <Hero /> */}
      {/* <SteamboatScoreboard /> */}
      {/* {displayPreviousEventScoreboard && <BridgeScoreboard />} */}
      {/* <CNYSEFScoreboard /> */}
      {/* <NYSSCScoreboard /> */}
      <RocketsScoreboard />
      {/* <HeroBridges /> */}
      {/* <Scoreboard /> */}
      {/* <BridgeScoreboard /> */}
      {/* {displayPreviousEventScoreboard && <RoboticsScoreboard />} */}
      {/* <Scoreboard /> */}
      {/* <UpcomingEvents /> */}
      {/* <Cta /> */}
      {/* <FooterStandard /> */}
    </>
  );
};

export default Landing;
