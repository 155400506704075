import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

const MainSupportRoute = () => {
  useEffect(() => {
    window.location.href = 'https://sites.google.com/view/moststemchallenge/';
  }, []);
  return <div>Redirecting you to the support website...</div>;
};

const RoboticsRoute = () => {
  useEffect(() => {
    window.location.href =
      'https://sites.google.com/view/moststemchallenge/guides/micron-robotics';
  }, []);

  return <div>Redirecting to you to the Micron Robotics support pages...</div>;
};

const SupportRoutes = () => {
  return (
    <>
      <Routes>
        <Route index element={<MainSupportRoute />} />
        <Route path="/robotics" element={<RoboticsRoute />} />
        <Route path="/micron-robotics" element={<RoboticsRoute />} />
        <Route path="*" element={<Navigate to="/errors/404" />} />
      </Routes>
    </>
  );
};

export default SupportRoutes;
