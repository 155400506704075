// Import airtable from 'airtable';
import Airtable from 'airtable';
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY
});

const rocket_airtable = Airtable.base('appKmduywngjp4CQh');
export default rocket_airtable;

export const scoring_table = rocket_airtable('scoring');
export const event_table = rocket_airtable('event');
